import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

export const JiskefetPageTemplate = ({ image, videos }) => (
  <Layout style={{ textAlign: 'center' }}>
    <img
      css={{ maxWidth: '100%', width: 650, borderRadius: 7 }}
      src={image}
      alt=""
    />
    {videos.map(({ title, link }) => (
      <div
        key={title + link}
        css={{
          textAlign: 'left',
          display: 'flex',
          flexWrap: 'wrap',
          '>div': {
            padding: '1em',
            flex: 1,
          },
          h3: { margin: 0 },
        }}
      >
        <div style={{ padding: '1em' }}>
          <h3>{title}</h3>
        </div>
        <div style={{ padding: '1em' }}>
          <a href={link}>{link}</a>
        </div>
      </div>
    ))}
  </Layout>
)

export default ({ data }) => {
  const { image, videos } = data.pagesYaml
  return <JiskefetPageTemplate image={image} videos={videos} />
}

export const pageQuery = graphql`
  query JiskefetPage($id: String!) {
    pagesYaml(id: { eq: $id }) {
      image
      videos {
        title
        link
      }
    }
  }
`
